<template>
  <!-- 租车网点管理 -->
  <div class="CarRentalPointManage">
    <el-button
      v-if="listFind('添加取车点')"
      type="primary"
      size="small"
      @click="addBtnFun"
      >添加网点</el-button
    >
    <Table
      :col-class-type="true"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              v-if="listFind('查看')"
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow, '查看')"
              >查看</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('编辑')"
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow, '编辑')"
              >编辑</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <el-dialog
      v-if="show"
      :title="headerText"
      :visible.sync="show"
      width="1000px"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="closePopupFun"
    >
      <div class="contentPopup">
        <div class="left">
          <el-form
            ref="Form"
            :model="pointForm"
            :rules="rules"
            label-width="140px"
            label-position="right"
            class="demo-form dialog-form"
          >
            <el-form-item label="网点名称：" prop="pickLocationName">
              <el-input
                v-model="pointForm.pickLocationName"
                :disabled="disabled"
                onkeyup="this.value=this.value.replace(/\s+/g,'')"
                clearable
                maxlength="30"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!-- 7.6  网点类型 -->
            <el-form-item label="网点类型：" prop="businessLocationType">
              <el-select
                v-model="pointForm.businessLocationType"
                :disabled="disabled"
                placeholder="请选择"
              >
                <el-option
                  v-for="items in businessLocationTypeList"
                  :key="items.id"
                  :label="items.label"
                  :value="items.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属城市：" prop="cityName">
              <el-select
                v-model="pointForm.cityName"
                :disabled="disabled"
                placeholder="请选择"
                @change="cityListChange"
              >
                <el-option
                  v-for="items in countyCityList"
                  :key="items.id"
                  :label="items.cityName"
                  :value="{ value: items.id, label: items.cityName }"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属区县：" prop="districtName">
              <el-select
                v-model="pointForm.districtName"
                :disabled="disabled"
                placeholder="请选择"
                @change="countyChange"
              >
                <el-option
                  v-for="items in countyDataList"
                  :key="items.id"
                  :label="items.districtName"
                  :value="{ value: items.id, label: items.districtName }"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属企业：" prop="carServiceId">
              <companySelect
                :disabled="disabled"
                :clearable="true"
                :company-tree="companyList"
                :valuename="valueAddName"
                :value="valueAdd"
                @getValue="getAddGroupId"
                @getName="getName"
              ></companySelect>
            </el-form-item>
            <el-form-item label="详细地址：">
              <el-input
                v-model="pointForm.detailAddress"
                disabled="disabled"
                type="textarea"
                autosize
              ></el-input>
            </el-form-item>
            <el-form-item label="网点描述：">
              <el-input
                v-model="pointForm.description"
                :disabled="disabled"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="启用：">
              <el-switch
                v-model="pointForm.status"
                :disabled="disabled"
                active-color="#13ce66"
                inactive-color="#E3E3E3"
                @click.stop.native
              ></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <div v-show="disabled" class="mapMask"></div>
          <div v-show="!disabled" class="searchBox">
            <i class="iconfont its_gy_sousuo"></i>
            <el-input
              id="tipinput"
              v-model="searchInput"
              clearable
              type="text"
              placeholder="输入搜索地址"
            ></el-input>
            <div class="searchContent">
              <div
                v-for="(item, index) in searchList"
                :key="index"
                class="content-address"
                @click="searchClick(item)"
              >
                <span class="address">{{ item.name }}</span>
                <span class="addressDetail"
                  >{{ `${item.pname}${item.cityname}${item.address}` }}
                </span>
              </div>
            </div>
          </div>
          <div id="map" class="map"></div>
        </div>
      </div>
      <span v-cloak slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="closePopupFun"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="confirmFun"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  rentalPointAddAPI,
  rentalCityListAPI,
  rentalDistrictListAPI,
  getCompanyTree,
  rentalTableDataAPI,
  rentalTableDataUpdateAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      popupStatus: "",
      searchList: [],
      searchInput: "",
      disabled: false,
      businessLocationTypeList: [
        // { value: 0, label: "取车点" },
        { value: 2, label: "取车点，还车点" },
        { value: 1, label: "还车点" },
      ], //网点类型数据
      countyCityList: [], // 取车点城市列表
      countyDataList: [], //所属区县列表数据
      pointForm: {
        pickLocationName: "", //取车点名称
        businessLocationType: null, //网点类型
        cityId: "", //所属城市Id
        cityName: "", //所属城市名
        districtId: "", //所属区县ID
        districtName: "", //所属区县名
        carServiceId: "", //所属企业ID
        carServiceName: "", //所属企业
        detailAddress: "", //详细地址
        latitude: "", //纬度
        longitude: "", //经度
        description: "", //取车点描述
        status: false,
      },
      rules: {
        pickLocationName: [
          { required: true, message: "请填写网点名称", trigger: "blur" },
        ],
        businessLocationType: [
          { required: true, message: "请选择网点类型", trigger: "change" },
        ],
        cityName: [
          { required: true, message: "请选择所属城市", trigger: "change" },
        ],
        districtName: [
          { required: true, message: "请选择所属区县", trigger: "change" },
        ],
        carServiceId: [
          { required: true, message: "请选择所属企业", trigger: "change" },
        ],
      },
      show: false,
      headerText: "",
      tableData: [],
      titleName: [
        {
          title: "网点名称",
          props: "pickLocationName",
        },
        {
          title: "网点类型",
          props: "businessLocationType",
          SpecialJudgment: (res) => {
            return this.businessLocationTypeList.find(
              (item) => item.value == res
            )?.label;
          },
        },
        {
          title: "城市",
          props: "cityName",
        },
        {
          title: "区县",
          props: "districtName",
        },
        {
          title: "所属企业",
          props: "carServiceName",
        },
        {
          title: "详细地址",
          props: "detailAddress",
        },
        {
          title: "网点描述",
          props: "description",
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    ...params,
                    status: params.status === 0 ? 1 : 0,
                  };
                  this.rentalTableDataUpdateFun(obj);
                },
              },
            });
          },
        },
      ],
      companyList: [],
      valueAddName: null,
      valueAdd: null,
      map: null,
      marker: null,
      address: "四川",
    };
  },
  created() {
    this.getCompanyList();
  },
  mounted() {
    this.renderData();
    rentalCityListAPI({ status: 0 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.countyCityList = res.data;
      }
    });
  },
  watch: {
    searchInput: {
      handler(val) {
        if (val) this.searchFun(val);
      },
    },
  },
  methods: {
    // 点击编辑按钮
    updateBtn(
      {
        id,
        pickLocationName,
        businessLocationType,
        cityId,
        cityName,
        districtId,
        districtName,
        carServiceId,
        carServiceName,
        detailAddress,
        latitude,
        longitude,
        description,
        status,
      },
      STATUSBTN
    ) {
      this.headerText = STATUSBTN;
      if (STATUSBTN == "编辑") {
        this.address = districtName;
      } else if (STATUSBTN == "查看") {
        this.disabled = true;
      }
      this.show = true;
      this.pointForm = {
        id,
        pickLocationName,
        businessLocationType,
        cityId,
        cityName,
        districtId,
        districtName,
        carServiceId,
        carServiceName,
        detailAddress,
        latitude,
        longitude,
        description,
        status: status === 0 ? true : false,
      };
      this.cityListChange({ value: cityId, label: cityName });
      this.$nextTick(() => {
        this.initMap();
        this.valueAddName = carServiceName;
        this.valueAdd = carServiceId;
      });
    },
    // 修改取车点数据
    rentalTableDataUpdateFun({
      id,
      pickLocationName,
      businessLocationType,
      cityId,
      cityName,
      districtId,
      districtName,
      carServiceId,
      carServiceName,
      detailAddress,
      latitude,
      longitude,
      description,
      status,
    }) {
      rentalTableDataUpdateAPI({
        id,
        pickLocationName,
        businessLocationType,
        cityId,
        cityName,
        districtId,
        districtName,
        carServiceId,
        carServiceName,
        detailAddress,
        latitude,
        longitude,
        description,
        status,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
          this.closePopupFun();
        }
      });
    },
    // 表格数据渲染
    renderData() {
      rentalTableDataAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    searchClick(item) {
      this.map.setCenter([item.location.lng, item.location.lat]);
      this.map.setZoom(16);
      this.pointForm.detailAddress = item.address;
      this.pointForm.latitude = item.location.lat;
      this.pointForm.longitude = item.location.lng;
      this.searchList = [];
      this.searchInput = "";
    },
    // 所属城市下拉框change
    cityListChange(val) {
      this.pointForm.cityId = val.value;
      this.pointForm.cityName = val.label;
      // 获取区县数据
      rentalDistrictListAPI({ cityId: val.value, status: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.countyDataList = res.data;
        }
      });
    },
    //  区县下拉框change
    countyChange(val) {
      this.pointForm.districtId = val.value;
      this.pointForm.districtName = val.label;
    },
    searchFun(val) {
      let vm = this;
      AMap.plugin("AMap.PlaceSearch", function () {
        var autoOptions = {
          city: vm.address,
          extensions: "all",
          citylimit: true, //是否强制在设置的城市内搜索
          autoFitView: true,
          children: 1,
        };
        var placeSearch = new AMap.PlaceSearch(autoOptions);
        placeSearch.search(val, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          if (result.info == "OK") {
            vm.searchList = result.poiList.pois;
          }
        });
      });
    },
    // 实例化地图
    initMap() {
      this.map = null;
      this.map = new AMap.Map("map", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.positionPicker();
    },
    // 拖拽选址方法
    positionPicker() {
      let vm = this;
      // 地图拖动的时候清空文本标记Marker Text
      this.map.on("dragging", function () {
        vm.map.clearMap();
      });
      this.map.on("zoomchange", function () {
        vm.map.clearMap();
      });
      if (this.pointForm.detailAddress) {
        vm.map.setCenter([this.pointForm.longitude, this.pointForm.latitude]);
        vm.marker = new AMap.Text({
          text: vm.pointForm.detailAddress,
          zIndex: 777,
          anchor: "center",
          offset: new AMap.Pixel(0, -60),
          style: {
            "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
            padding: "14px",
          },
          position: [vm.pointForm.longitude, vm.pointForm.latitude],
        });
        vm.marker.setMap(vm.map);
      }
      // 地图拖拽
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          zIndex: 666,
          iconStyle: {
            //自定义外观
            url: require("@/assets/images/home/from_icon2.png"),
            ancher: [15, 30],
            size: [30, 39],
          },
        });
        vm.pickerPosition.on("success", function (positionResult) {
          if (positionResult.regeocode.pois.length > 0) {
            vm.pointForm.detailAddress = positionResult.regeocode.pois[0].name;
            vm.pointForm.latitude =
              positionResult.regeocode.pois[0].location.lat;
            vm.pointForm.longitude =
              positionResult.regeocode.pois[0].location.lng;
          }
          vm.map.clearMap();
          vm.marker = new AMap.Text({
            text: positionResult.regeocode.pois[0].name,
            zIndex: 777,
            anchor: "center",
            offset: new AMap.Pixel(0, -60),
            style: {
              "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
              padding: "14px",
            },
            position: [
              positionResult.position.lng,
              positionResult.position.lat,
            ],
          });
          vm.marker.setMap(vm.map);
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    // 点击添加取车点
    addBtnFun() {
      this.show = true;
      this.headerText = "添加网点";
      this.$nextTick(() => {
        this.initMap();
      });
    },
    // 取消按钮回调
    closePopupFun() {
      this.show = false;
      this.disabled = false;
      this.valueAddName = null;
      this.valueAdd = null;
      this.pointForm = {
        pickLocationName: "", //取车点名称
        businessLocationType: "", //网点类型
        cityId: "", //所属城市Id
        cityName: "", //所属城市名
        districtId: "", //所属区县ID
        districtName: "", //所属区县名
        carServiceId: "", //所属企业ID
        carServiceName: "", //所属企业
        detailAddress: "", //详细地址
        latitude: "", //纬度
        longitude: "", //经度
        description: "", //取车点描述
      };
    },
    // 确定按钮回调
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          let obj = this.deepClone(this.pointForm);
          if (obj.status == true) {
            obj.status = 0;
          } else {
            obj.status = 1;
          }
          if (this.headerText == "添加网点") {
            rentalPointAddAPI(obj).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.closePopupFun();
              }
            });
          } else if (this.headerText == "编辑") {
            this.rentalTableDataUpdateFun(obj);
          }
        }
      });
    },
    // 企业名称emit
    getName(val) {
      this.pointForm.carServiceName = val;
    },
    // 企业回显
    getAddGroupId(v) {
      if (v == null) {
        this.pointForm.carServiceId = "";
      } else {
        this.pointForm.carServiceId = v;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.Table {
  margin-top: 20px;
}
.mapMask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.searchBox {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 230px !important;
  height: 28px !important;
  z-index: 2056;
  /deep/.el-input {
    input {
      padding-left: 30px;
    }
  }
  i {
    position: absolute;
    font-size: 20px;
    z-index: 2057;
    top: 4px;
    left: 6px;
  }
  .searchContent {
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
  }
  .address {
    width: calc(100% - 8px);
    display: inline-block;
    cursor: pointer;
    padding: 4px 4px 0 4px;
    line-height: 24px;
  }
  .addressDetail {
    width: calc(100% - 8px);
    display: inline-block;
    border-bottom: 1px solid #f4f4f4;
    cursor: pointer;
    padding: 0 4px 4px 4px;
    line-height: 24px;
    font-size: 12px;
    color: #afafaf;
  }
}
/deep/.el-textarea__inner {
  //el_input中的隐藏属性
  resize: none; //主要是这个样式
  height: 100px;
}
.contentPopup {
  display: flex;
  .dialog-form {
    padding: 0;
  }
  .left {
    width: 40%;
  }
  .right {
    width: 60%;
    padding-right: 20px;
    position: relative;
    .map {
      width: 100%;
      height: 478px;
    }
  }
}
.CarRentalPointManage {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
}
</style>
